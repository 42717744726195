html,
body,
#root {
  height: 100%;
  background-color: #231f20;
  font-size: 14px;
  color: white;
}

.container {
  margin-left: 10%;
  margin-right: 10%;
}

.center {
  text-align: center;
}

.footer {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 30px;
}

.button {
  background-color: #61ad70;
  padding: 10px 10px;
  text-decoration: none;
  color: white;
  border-radius: 4px;
  margin-top: 25px;
  cursor: pointer;
}

.button:hover {
  background-color: #3c7047;
}

.mt-4 {
  margin-top: 30px;
}

.about {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 18px;
  flex-wrap: wrap;
}

.about div {
  margin: auto 50px;
}

.about img,
.about div {
  width: 40%;
}

.home {
  height: 100%;
  background: rgba(0, 0, 0, 0.65) url("./img/background.png");
  background-blend-mode: multiply;
  background-size: cover;
}

.head {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.head img {
  max-width: 90%;
}

.head .subtitle {
  color: white;
  font-size: 20px;
  text-align: center;
}

._form-branding {
  display: none;
}

@media screen and (max-width: 500px) {
  .about img,
  .about div {
    width: 100%;
  }

  .about img {
    margin-bottom: 15px;
  }

  .about.reverse {
    flex-flow: wrap-reverse;
  }
}
